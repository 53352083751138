@tailwind base;
@tailwind components;
@tailwind utilities;

button.MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary,
[type="button"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary,
[type="reset"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary,
[type="submit"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary {
  background-color: #232426;
}

button.MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary:hover,
[type="button"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary:hover,
[type="reset"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary:hover,
[type="submit"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary:hover {
  background-color: #2f0f9f;
}

button.MuiButtonBase-root.MuiButton-contained.Mui-disabled,
[type="button"].MuiButtonBase-root.MuiButton-contained.Mui-disabled,
[type="reset"].MuiButtonBase-root.MuiButton-contained.Mui-disabled,
[type="submit"].MuiButtonBase-root.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3);
}

button.MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary,
[type="button"].MuiButtonBase-root.MuiButton-colorSecondary,
[type="reset"].MuiButtonBase-root.MuiButton-colorSecondary,
[type="submit"].MuiButtonBase-root.MuiButton-colorSecondary {
  background-color: #e2e8f0;
}

button.MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary:hover,
[type="button"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary:hover,
[type="reset"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary:hover,
[type="submit"].MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary:hover {
  background-color: #1e293b;
  color: #f8fafc;
}

@import "./prism.css";

/**
 * https://github.com/tailwindlabs/tailwindcss/discussions/2394
 * https://github.com/tailwindlabs/tailwindcss/pull/5732
 **/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
